import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <div className="text-center  flex-col flex justify-center" style={{minHeight: '400px', height: '90vh'}}>
    <SEO title="404: Not found" />
    <h1 className="text-fadeblack">Page Not Found!</h1>
    <h4 className="p-font m-4">We're sorry, You just hit a route that doesn&#39;t exist....</h4>
  </div>
)

export default NotFoundPage
